<template>
    <Wrapper width="1100px" justify="center" :padding="{y: 'small'}">
        <Title :data="data" />
        <Wrapper v-if="data.icon_text_module.length" :padding="{ y: 'small' }">
           <IconText v-for="item,index in data.icon_text_module" :data="{ ...item, color: data.color}" :key="`icon-${index}`" /> 
        </Wrapper>
        <Wrapper justify="center" gap="20px">
            <ButtonGreen v-for="button, index in data.buttons" :button="button.button" :key="`btn-${index}`"></ButtonGreen>
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'Introduction'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import Title from '@UI/Typography/Title/Main.vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import IconText from '@Layout/IconText/Main.vue';
import ButtonGreen from '@UI/Buttons/ButtonGreen.vue';

defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});

</script>