<template>
   <ul :class="style.list">
        <slot />
    </ul> 
</template>

<script>
    export default {
        name: 'List'
    }
</script>

<script setup>
import style from './Main.module.scss';
</script>