<template>
    <Wrapper width="1100px" gap="30px" justify="space-between">
        <Title :data="data" />
        <template v-if="data.layout === 'boxes'">
            <PostBox v-for="post, index in posts" :key="`post-${index}`" :post="post" :class="style.postbox" />
        </template>
        <template v-else>
            <PostList :posts="posts" />
        </template>
        <Wrapper justify="center" v-if="data.has_load_more_button && posts">
            <ButtonGreen 
                :button="{
                    title: 'Δείτε τα επόμενα άρθρα'
                }"
                type="loadmore"
                @clicked="loadArticles"
                :class="{'loading': isLoading}"
                v-if="hasArticles"
            />
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'ListOfArticles'
    }
</script>

<script setup>
    import {  defineProps, ref, computed } from 'vue';
    import ButtonGreen from '@UI/Buttons/ButtonGreen.vue';
    import axios from 'axios';
     
    import Title from '@UI/Typography/Title/Main.vue';
    import host from '@Helpers/host.js';
    import Wrapper from '@UI/Wrapper/Main.vue';
    import PostBox from '@Layout/PostBox/Main.vue';
    import PostList from '@Layout/PostList/Main.vue';
    import style from './Main.module.scss';

    const props = defineProps({
        data: {
            type: Object,
            required: true
        }
    });

    const posts = ref([]);
    const postOffset = ref(+props.data.offset);
    const isLoading = ref(true);
    const hasArticles = ref(true);

    const postsParams = computed( () => (props.data.auto_feed) ? `autofeed=true` : `ids=${props.data.posts.join(',')}`);

    const postsAxiosCall = () => axios.get(`${host}/wp-json/multiple/posts?offset=${postOffset.value}&numberofposts=${props.data.number_of_posts}&${postsParams.value}` ).then( response => {
        if(response.data) {
            posts.value.push(...response.data);

            if(response.data.length < props.data.number_of_posts) {
                hasArticles.value = false;
            }
        }else {
            hasArticles.value = false;
        }
        isLoading.value = false;
    } ).catch(e => console.log(e));
    
    postsAxiosCall();
    
    const loadArticles = () => {
        postOffset.value =  postOffset.value*1 + props.data.number_of_posts*1;
        isLoading.value = true;
        postsAxiosCall();
    }
</script>