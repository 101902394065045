<template>
    <Wrapper>
        <Wrapper>
            <div v-html="data.content" :class="classes"/>  
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'SimpleContent'
    }
</script>

<script setup>
import Wrapper from '@UI/Wrapper/Main.vue';
import { defineProps } from 'vue';
import style from './Main.module.scss';

const props = defineProps({
    data: {
        type: [String, null],
        default: null
    }
});

const classes = [`text-${props.data.color}`,style.content];
</script>