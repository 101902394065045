<template>
    <div :class="classes" v-html="menu"></div>
</template>

<script>
    export default {
        name: 'Menu'
    }
</script>

<script setup>
    import { ref, defineProps, computed } from 'vue';
    import host from '@Helpers/host.js';
     
    import axios from 'axios';
    import style from './Main.module.scss';

    const menu = ref(null);

    const props = defineProps({
        menuname: {
            type: [null, String],
            default: null
        },
        column: {
           type: Boolean,
            default: false 
        },
    });
    
    axios.get(`${host}/wp-json/menus/${props.menuname}` ).then(response => menu.value = response.data ).catch(e => console.log(e));

    const classes = computed( () => [
    style.menu,
    ...(props.column) ? [style.column] : []
    ]);
</script>