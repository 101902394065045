<template>
    <Wrapper direction="column" align="center" width="310px" text-align="center">
        <component :is="(data.link) ? 'a' : 'div'" :href="data.link">
            <Image :src="data.icon.url" :alt="`icon-${data.text}`" width="160" height="160"/>
            <Paragraph :color="data.color">
                {{ data.text }}
            </Paragraph>
        </component>
    </Wrapper>  
</template>

<script>
    export default {
        name: 'IconText'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import Paragraph from '@UI/Typography/Paragraph/Main.vue';
import Image from '@UI/Image/Main.vue';

defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});
</script>
