<template>
    <div class="position-relative">
        <Image :src="data.image.url" width="100%" :height="isSmallScreen ? `${data.image_height/2}px` : `${data.image_height}px`" />
        <Wrapper position="relative" :transform="isSmallScreen ? 'translateY(0)' : 'translateY(-50%)'" :class="style.textBox"  width="1245px">
            <TextBox text-align="center" background="blue" padding="xsmall" >
               <Title :class="style.textboxheading" :data="{ ...data, color: 'white' }" />
            </TextBox>
        </Wrapper>
    </div>
</template>

<script>
    export default {
        name: 'Hero'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import { isSmallScreen } from '@Helpers/isSmallScreen.js';
import Image from '@UI/Image/BackgroundImage.vue';
import TextBox from '@UI/TextBox/Main.vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import Title from '@UI/Typography/Title/Main.vue';
import style from './Main.module.scss';

defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});
</script>