<template>
    <div>
        <a :href="post.guid" :class="style.postBox">
            <Image v-if="post?.thumbnail" :src="post.thumbnail" width="100%" height="100%" :class="style.postImage" />
            <div :class="style.ribbonTitle">
                <Title :data="{
                    heading: {
                        look_like_type: 'h6',
                        type: 'h4',
                        title: post.title,
                        color: 'white'
                    }
                }"/>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'PostBox'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import Image from '@UI/Image/BackgroundImage.vue';
import style from './Main.module.scss';
import Title from '@UI/Typography/Title/Main.vue';

defineProps({
    post: {
        type: Object,
        default: () => {}
    }
});

</script>