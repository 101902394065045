<template>
    <div class="main-body" :id="`main-body-${getPageSlug()}`">
        <template v-for="section in sections">
            <AsyncComponent v-if="!section.hide_section" :key="section.acf_fc_layout" :data="section" />
        </template>
    </div>
</template>

<script>
    export default {
        name: 'PageBuilder'
    }
</script>

<script setup>
    import { ref, defineEmits } from 'vue';
    import host from '@Helpers/host.js';
     
    import getPageSlug from '@Helpers/getPageSlug.js'
    import axios from 'axios';
    import AsyncComponent from '@Layout/AsyncComponent/Main.vue';

    const sections = ref({});
    const emit = defineEmits('dataReady');
   
    axios.get(`${host}/wp-json/acf/page-builder?pageslug=${getPageSlug()}` ).then(response => {sections.value = response.data; emit('dataReady');} ).catch(e => console.log(e));
</script>