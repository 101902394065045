<template>
    <component :is="as" :class="classes">
        <slot />
    </component>
</template>

<script>
    export default {
        name: 'TitleHelper'
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    as: {
        type: String,
        default: 'h1'
    },
    styleAs: {
        type: [null,String],
        default: null
    },
    color: {
        type: String,
        default: 'black'
    },
    textAlignment: {
        type: String,
        default: 'left'
    }
});

const classes = computed( () => [
    'w-100',
    `text-${props.color}`,
    `text-align-${props.textAlignment}`,
    ...(props.styleAs) ? [props.styleAs] : []
] );
</script>