<template>
    <div v-if="showSections" :class="style.showSectionsName">{{ component.name }}</div>
    <component :is="component.type" :data="data" :class="sectionClasses({ ...data, customClasses: customClasses })" />
</template>

<script>
    export default {
        name: 'AsyncComponent'
    }
</script>

<script setup>
    import { computed, defineProps } from 'vue';
    import Hero from '@Sections/Hero/Main.vue';
    import Introduction from '@Sections/Introduction/Main.vue';
    import Carousel from '@Sections/Carousel/Main.vue';
    import SimpleHeading from '@Sections/SimpleHeading/Main.vue'
    import SimpleContent from '@Sections/SimpleContent/Main.vue'
    import SimpleImage from '@Sections/SimpleImage/Main.vue'
    import ListOfArticles from '@Sections/ListOfArticles/Main.vue'
    import ContactForm from '@Sections/ContactForm/Main.vue'
    import BlankSpace from '@Sections/BlankSpace/Main.vue'
    import sectionClasses from '@Helpers/sectionSettings.js';

    import style from './Main.module.scss';

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const showSections = urlParams.has('show_sections');

    const componentMap = {
        hero: {
            type: Hero,
            name: 'Hero'
        },
        introduction: {
            type: Introduction,
            name: 'Introduction'
        },
        carousel: {
            type: Carousel,
            name: 'Carousel'
        },
        simple_heading: {
            type: SimpleHeading,
            name: 'Simple Heading'
        },
        simple_content: {
            type: SimpleContent,
            name: 'Simple Content'
        },
        simple_image: {
            type: SimpleImage,
            name: 'Simple Image'
        },
        list_of_articles: {
            type: ListOfArticles,
            name: 'List Of Articles'
        },
        contact_form: {
            type: ContactForm,
            name: 'Contact Form'
        },
        blank_space: {
            type: BlankSpace,
            name: 'Blank Space'
        }
    }

    const props = defineProps({
        data: {
            type: Object,
            default: () => {}
        }
    });

    const customClasses = {
        [style.asyncComponent] : true,
        [style.showSections] : showSections
    };

    const component = computed( () => componentMap[props.data.acf_fc_layout] );
</script>