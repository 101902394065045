<template>
    <Wrapper>
        <Wrapper :justify="data.image_alignment">
            <Image :src="data.image.url" :width="data.image.width" :height="data.image.height" :alt="data.image.alt" />
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'SimpleImage'
    }
</script>

<script setup>
import Image from '@UI/Image/Main.vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import { defineProps } from 'vue';

defineProps({
    data: {
        type: [Object, null],
        default: null
    }
});
</script>