<template>
    <img :class="style.image" :alt="alt" :width="width" :height="height" :src="src" />
</template>

<script>
    export default {
        name: 'Image'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import style from './Main.module.scss';

defineProps({
    src: {
        required: true,
        type: String
    },
    width: {
        required: true,
        type: [Number,String]
    },
    height: {
        required: true,
        type: [Number,String]
    },
    alt: {
        required: true,
        type: String
    }
});
</script>