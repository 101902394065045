const sectionClasses = ({section_padding, section_background, customClasses}) => {

    return [
        ...(section_padding) ? [section_padding] : [],
        ...(section_background) ? [`bg-${section_background}`] : [],
        customClasses
    ]
}
;

export default sectionClasses;