<template>
    <div :class="style.mobileMenu">
        <Logo width="200" height="30"/>
        <div :class="classes" v-html="menu"></div>
    </div>
</template>

<script>
    export default {
        name: 'MobileMenu'
    }
</script>

<script setup>
    import { ref, defineProps, computed } from 'vue';
    import host from '@Helpers/host.js';
    import Logo from '@UI/Logo/Main.vue';
     
    import axios from 'axios';
    import style from './Main.module.scss';

    const menu = ref(null);

    const props = defineProps({
        menuname: {
            type: [null, String],
            default: null
        },
        column: {
           type: Boolean,
            default: false 
        },
    });
    
    axios.get(`${host}/wp-json/menus/${props.menuname}` ).then(response => menu.value = response.data ).catch(e => console.log(e));

    const classes = computed( () => [
    ...(props.column) ? [style.column] : []
    ]);
</script>