<template>
    <Title 
        :style-as="data.heading.look_like_type" 
        :as="data.heading.type" 
        :color="data.heading.color" 
        :text-alignment="data.heading.text_alignment"
    >
        {{ data.heading.title }}
    </Title>
</template>

<script>
    export default {
        name: 'Title'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import Title from './Title.vue';

defineProps({
    data: {
        default: () => {},
        type: Object
    }
});
</script>