<template>
    <span :class="[style.burgerIcon,{[style.active]: active}]" @click="handleClick">
        <span />
        <span />
        <span />
    </span>
</template>

<script setup>
import style from './Main.module.scss';
import { defineEmits, ref } from 'vue';

const emit = defineEmits('clicked');
const active = ref(false);

const handleClick = () => {
    active.value = !active.value;
    emit('clicked');
}
</script>