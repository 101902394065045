<template>
    <footer v-if="footer" :class="style.footer">
        <Wrapper fluid :gap="isSmallScreen ? '40px' : '0'" justify="center">
            <Wrapper :width="isSmallScreen ? '100%' : '30%'" direction="column" gap="20px">
                <Title :data="{ heading: footer.contact_heading_heading }" color="white" />
                <List>
                    <ListItem :key="`contact-list-1`">
                        {{ footer.phone_label }}: <a :href="`tel:${footer.phone_to_call}`"> {{ footer.phone_to_show }}</a>
                    </ListItem>
                    <ListItem :key="`contact-list-2`">
                        {{ footer.email_label }}: <a :href="`mailto:${footer.email}`"> {{ footer.email }}</a>
                    </ListItem>
                </List>
            </Wrapper>
            <Wrapper :width="isSmallScreen ? '100%' : '30%'" direction="column" gap="20px">
                <Title :data="{ heading: footer.useful_heading_heading }"  color="white" />
                <List uniquekey="useful-list">
                    <ListItem v-for="item, index in footer.menu_item" :key="`useful-list-${index}`">
                        <a :href="item.menu_item.url">{{ item.menu_item.title }}</a>
                    </ListItem>  
                </List>
            </Wrapper>
            <Wrapper :width="isSmallScreen ? '100%' : '40%'" direction="column" gap="20px">
                <Image 
                    :src="footer.icons.url" 
                    :width="footer.icons.width" 
                    :height="footer.icons.height" 
                    alt="Footer thumbnail" 
                />   
                <small class="text-white">Watera | All rights reserved</small>
            </Wrapper>
        </Wrapper>
    </footer>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<script setup>
import { ref } from 'vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import { isSmallScreen } from '@Helpers/isSmallScreen.js';
import Image from '@UI/Image/Main.vue';
import axios from 'axios';
import host from '@Helpers/host.js';
import style from './Main.module.scss';
import Title from '@UI/Typography/Title/Main.vue';
import List from '@UI/List/Main.vue';
import ListItem from '@UI/List/ListItem.vue';
 

const footer = ref(null);

axios.get(`${host}/wp-json/acf/footer` ).then(response => footer.value = response.data ).catch(e => console.log(e));
</script>
