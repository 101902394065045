<template>
    <button v-if="type === 'loadmore'" :class="style.button" @click.prevent="$emit('clicked')">{{ button.title }}</button>
    <button v-else :class="style.button" @click.prevent="goTo">{{ button.title }}</button>
</template>

<script>
    export default {
        name: 'ButtonGreen'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import style from './Main.module.scss';

const props  = defineProps({
    button: {
        type: Object,
        default: () => {}
    },
    type: {
        type: String,
        default: ''
    }
});

const goTo = () => window.location.href = props.button?.url;
</script>