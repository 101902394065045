<template>
    <Header :transparent="pageSettings.transparent_header && isTransparentFromScroll" :position="pageSettings.position_header" />
    <PageBuilder @dataReady="dataReady"/>
    <Footer />
</template>

<script setup>
    import Header from '@Sections/Header/Main.vue';
    import Footer from '@Sections/Footer/Main.vue';
    import PageBuilder from './PageBuilder.vue';
    import { ref } from 'vue';
    import axios from 'axios';
    import host from '@Helpers/host.js';
     
    import getPageSlug from '@Helpers/getPageSlug.js';

    const pageSettings = ref({});
    const showBuilder = ref(false);
    const isTransparentFromScroll = ref(window.scrollY === 0);

    const dataReady = () => {
        showBuilder.value - true;
    }

    axios.get(`${host}/wp-json/acf/page-settings?pageslug=${getPageSlug()}` ).then(response => pageSettings.value = response.data ).catch(e => console.log(e));

    window.addEventListener('scroll', () => {
        isTransparentFromScroll.value = (window.scrollY < 10); 
    })
</script>