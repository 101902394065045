<template>
    <div 
        :class="classes" 
        :style="styles"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Wrapper'
    }
</script>

<script setup>
    import { defineProps, computed } from 'vue';
    import style from './Main.module.scss';

    const props = defineProps({
        padding: {
            type: [String, Object, null],
            default: null
        },
        width: {
            type: [String, null],
            default: null
        },
        align: {
            type: [String, null],
            default: null
        },
        direction: {
            type: [String, null],
            default: null
        },
        justify: {
           type: [String, null],
            default: null
        },
        position: {
           type: [String, null],
            default: null
        },
        top: {
           type: [String, null],
            default: null
        },
        bottom: {
           type: [String, null],
            default: null
        },
        left: {
           type: [String, null],
            default: null
        },
        right: {
           type: [String, null],
            default: null
        },
        transform: {
           type: [String, null],
            default: null
        },
        gap: {
           type: [String, null],
            default: null
        },
        fluid: {
            type: Boolean,
            default: false
        },
        textAlign: {
           type: [String, null],
            default: null
        }
    });

    const classes = computed( () => [
        style.container,
        ...(props.align) ? [`align-items-${props.align}`] : [],
        ...(props.justify) ? [`justify-content-${props.justify}`] : [],
        ...(props.direction) ? [`direction-${props.direction}`] : [],
        ...(props.position) ? [`position-${props.position}`] : [],
        ...(props.textAlign) ? [`text-align-${props.textAlign}`] : [],
        ...(props.fluid) ? [style.fluid] : [],
        ...(props.padding) ? 
                (props.padding.x) ? [`padding-x-${props.padding.x}`] :  
                    (props.padding.y) ? [`padding-y-${props.padding.y}`] : [`padding-${props.padding}`]  :
                        []
    ] );

    const styles = computed( () => {
        const styleObj = {};

        if(props.top) {
            styleObj["top"] = props.top;
        }

        if(props.bottom) {
            styleObj["bottom"] = props.bottom;
        }

        if(props.left) {
            styleObj["left"] = props.left;
        }

        if(props.right) {
            styleObj["right"] = props.right;
        }

        if(props.transform) {
            styleObj["transform"] = props.transform;
        }

        if(props.width) {
            styleObj["width"] = props.width;
        }

        if(props.gap) {
            styleObj["gap"] = props.gap;
        }

        return styleObj;

    } );
</script>
