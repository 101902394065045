<template>
    <List :class="style.postList">
        <ListItem color="black" :class="style.postListItem" v-for="item,index in posts" :key="`post-list-${index}`">
            <ArrowRight width="25px" height="25px" /> <a :href="item.guid">{{ item.title }}</a>
        </ListItem>
    </List>
</template>

<script>
    export default {
        name: 'PostList'
    }
</script>

<script setup>
import ArrowRight from '@Icons/ArrowRight/Main.vue';
import List from '@UI/List/Main.vue';
import ListItem from '@UI/List/ListItem.vue';
import { defineProps } from 'vue';

import style from "./Main.module.scss";

defineProps({
    posts: {
        type: Object,
        required: true
    }
});
</script>