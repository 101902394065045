<template>
    <li :class="classes" ><slot /></li>
</template>

<script>
    export default {
        name: 'ListItem'
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: 'white'
    },
});

const classes = computed( () => [
    `text-${props.color}`,
] );
</script>