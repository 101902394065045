<template>
    <header :class="classes">
        <Wrapper align="center" justify="space-between" fluid>
            <Logo :width="isSmallScreen ? '200' : '272'" :height="isSmallScreen ? '30' : '40'"/>
            <Menu menuname="primary" v-if="!isSmallScreen" />
            <template v-else>
                <BurgerIcon @clicked="handleBurgerIconClick" />
                <MobileMenu menuname="primary" v-if="showMobileMenu" />
            </template>
        </Wrapper>
    </header>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>

<script setup>
    import { defineProps, computed, ref } from 'vue';
    import { isSmallScreen } from '@Helpers/isSmallScreen.js';
    import Wrapper from '@UI/Wrapper/Main.vue';
    import Menu from '@Layout/Menu/Main.vue';
    import MobileMenu from '@Layout/Menu/MobileMenu.vue';
    import Logo from '@UI/Logo/Main.vue';
    import BurgerIcon from '@Icons/BurgerIcon/Main.vue';

    import style from './Main.module.scss';

    const showMobileMenu = ref(false);

    const props = defineProps({
        transparent: {
            type: Boolean,
            default: false
        },
        position: {
            type: [null, String],
            default: null
        }
    });

    const handleBurgerIconClick = () => {
        console.log('clicked', showMobileMenu.value);
        showMobileMenu.value = !showMobileMenu.value;
        console.log('clicked', showMobileMenu.value);
    }

    const classes = computed(() => [
        style.header,
        ...(props.transparent) ? [style.transparent] : [],
        ...(props.position) ? [`position-${props.position}`] : []
    ]);
</script>