import { createApp } from 'vue'
import host from '@Helpers/host.js'
import App from './App.vue'

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register(host+'/wp-content/themes/foxy/service-worker.js');
    });
}

createApp(App).mount('#app')
