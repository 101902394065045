<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'TextBox'
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    color: {
        type: [null, String],
        default: null
    },
    background: {
        type: [null,String],
        default: null
    },
    padding: {
        type: [null,String],
        default: null
    },
    textAlign: {
        type: [null,String],
        default: null
    },
});

const classes = computed( () => [
    ...(props.background) ? [`bg-${props.background}`] : [],
    ...(props.color) ? [`text-${props.color}`] : [],
    ...(props.padding) ? [`padding-${props.padding}`] : [],
    ...(props.textAlign) ? [`text-align-${props.textAlign}`] : []
] );
</script>
