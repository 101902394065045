<template>
    <Wrapper>
        <Wrapper>
            <Title :data="{heading: data.heading}" />
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'SimpleHeading'
    }
</script>

<script setup>
import Wrapper from '@UI/Wrapper/Main.vue';
import Title from '@UI/Typography/Title/Main.vue';
import { defineProps } from 'vue';

defineProps({
    data: {
        type: [Object, null],
        default: null
    }
});
</script>