<template>
    <div 
        :class="style.image"
        :style="{ 
            backgroundImage: 'url(' + src + ')', 
            width: `${width}`, 
            height: `${height}`,
            backgroundRepeat: repeat,
            backgroundSize: size,
            backgroundPosition: position }" 
    />
</template>

<script>
    export default {
        name: 'BackgroundImage'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import style from './Main.module.scss';

defineProps({
    src: {
        required: true,
        type: String
    },
    size: {
        default: 'cover',
        type: String
    },
    repeat: {
        default: 'no-repeat',
        type: String
    },
    position: {
        default: 'center center',
        type: String
    },
    width: {
        required: true,
        type: String
    },
    height: {
        required: true,
        type: String
    },

});
</script>