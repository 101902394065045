<template>
    <Wrapper>
        <Wrapper width="1100px" direction="column" align="center" text-align="center" gap="50px">
            <Title :data="data" />
            <Carousel :data="data.carousel" />
            <ButtonGreen v-if="data.button" :button="data.button" />
        </Wrapper>
    </Wrapper>
</template>

<script>
    export default {
        name: 'CarouselSection'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import Carousel from '@Layout/Carousel/Main.vue';
import Wrapper from '@UI/Wrapper/Main.vue';
import Title from '@UI/Typography/Title/Main.vue';
import ButtonGreen from '@UI/Buttons/ButtonGreen.vue';

defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});
</script>