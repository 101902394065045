<template>
    <Wrapper width="1100px">
        <Title :data="data" />
        <div class="contact-form-7" :id="`contact-form-7-${data.contact_form}`" @submit="formSubmission">
            <div class="alert" :class="classes">
                {{ body.response_success }}
                {{ body.response_error }}
            </div>
            <form :class="style.form" :action="`${host}/wp-json/contact-form-7/v1/contact-forms/${data.contact_form}/feedback`" method="post">
                    <div :class="style.fieldWrapper">
                        <input id="your-name" :class="style.textInput" v-model="body.name" type="text" name="your-name" placeholder="Όνομα" required>
                    </div>
                    <div :class="style.fieldWrapper">
                        <input id="your-surname" :class="style.textInput" v-model="body.surname" type="text" name="your-surname" placeholder="Επώνυμο" required>
                    </div>
                    <div :class="style.fieldWrapper">
                        <input id="your-tel" :class="style.textInput" v-model="body.tel" type="tel" name="your-tel" placeholder="Τηλέφωνο" required>
                    </div>
                    <div :class="style.fieldWrapper">
                        <input id="your-email" :class="style.textInput" v-model="body.email" type="email" name="your-email" placeholder="Ηλεκτρονική Διεύθυνση" required>
                    </div>
                    <div :class="[style.fieldWrapper, style.full]">
                        <textarea id="details" v-model="body.details" name="details" :class="style.textareaInput" placeholder="Λεπτομέρειες" />
                    </div>
                    <button type="submit" :class="style.button">Αποστολή</button>
            </form>
        </div>
    </Wrapper>
</template>

<script>
    export default {
        name: 'ContactForm'
    }
</script>

<script setup>
import { defineProps, reactive, computed } from 'vue';
import host from '@Helpers/host.js';
import axios from 'axios';
import style from './Main.module.scss';
import Wrapper from '@UI/Wrapper/Main.vue';
import Title from '@UI/Typography/Title/Main.vue';

const body = reactive({
    name: '',
    surname: '',
    tel: '',
    email: '',
    details: '',
    response_success: null,
    response_error: null
});

const formSubmission = (event) => {
    event.preventDefault();

    const formElement = event.target;
    const { action } = formElement;

    try {
            const formData = new FormData();
            formData.set('your-name', body.name);
            formData.set('your-surname', body.surname);
            formData.set('your-tel', body.tel);
            formData.set('your-email', body.email);
            formData.set('details', body.details);
            axios.post(action,formData).then(r => {body.response_success = r.data.message}).catch(e => body.response_success = e.message);
        } catch (error) {
            console.log(error);
        }
}

defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});

const classes = computed( () => ({
    error: body.response_error,
    success: body.response_success
}) );

</script>