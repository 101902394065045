<template>
    <p :class="classes">
        <slot />
    </p>
</template>

<script>
    export default {
        name: 'Paragraph'
    }
</script>

<script setup>
import { defineProps } from 'vue';
import style from './Main.module.scss';

const props = defineProps({
    color: {
        type: String,
        default: 'black'
    }
});

const classes= [
   style.p,
   `text-${props.color}` 
]
</script>