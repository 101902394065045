<template>
<Wrapper width="1100px">
    <Carousel :breakpoints="breakpoints" :class="style.carousel" :items-to-scroll="1">
        <Slide v-for="slide, index in dataSrc" :key="`gallery-item-${index}`">
            <div class="item__slide">
                <template v-if="data.carousel_type === 'gallery'">
                    <Image :src="slide.url" width="" height="" :alt="`gallery image ${index}`" />
                </template>
                <template v-else>
                    <PostBox :post="slide" />
                </template>
            </div>
        </Slide>

        <template #addons>
            <Navigation v-if="settings.arrows">
                <template #next> 
                    <ArrowRight />
                </template>
                <template #prev>
                    <ArrowLeft />
                </template>
            </Navigation>
            <Pagination v-if="settings.pagination" />
        </template>
    </Carousel>
</Wrapper>
</template>

<script>
    export default {
        name: 'CarouselLayout'
    }
</script>

<script setup>
import { defineProps, computed, ref } from 'vue';
import axios from 'axios';
import host from '@Helpers/host.js';
 
import Wrapper from '@UI/Wrapper/Main.vue';
import 'vue3-carousel/dist/carousel.css';
import ArrowLeft from '@Icons/ArrowLeft/Main.vue';
import ArrowRight from '@Icons/ArrowRight/Main.vue';
import Image from '@UI/Image/Main.vue';
import PostBox from '@Layout/PostBox/Main.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import style from './Main.module.scss';

const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});

const posts = ref([]);

const postsParams = computed( () => (props.data.auto_feed) ? `autofeed=true` : `ids=${(props.data?.posts?.posts) ? props.data.posts.posts.join(',') : ''}`);

axios.get(`${host}/wp-json/multiple/posts?offset=${props.data.offset}&numberofposts=${props.data.numberofposts}&${postsParams.value}` ).then(response => posts.value = response.data ).catch(e => console.log(e));

const settings = computed( () => ({
    arrows: props.data.arrows,
    pagination: props.data.pagination,
}) );

const dataSrc = computed( () => (props.data.carousel_type === 'gallery') ? props.data.gallery : posts.value );

const breakpoints = {
    768: {
       itemsToShow: +props.data.number_of_slides,
       itemsToScroll: 1
    },
    650: {
       itemsToShow: 3,
       itemsToScroll: 1
    },
    480: {
       itemsToShow: 2,
       itemsToScroll: 1
    },
    0: {
       itemsToShow: 1,
       itemsToScroll: 1
    },
};

</script>

<style scoped>
.item__slide {
    margin: 0 7px;
    width: 100%;
}
</style>